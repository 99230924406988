@import "varriable";
@import "fonts";


.file-input-label {
  overflow: hidden;
  @extend .pos-r, .cur-p;

  &-clear {
    opacity: 0;
    visibility: hidden;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    @extend .pos-a, .h-100, .w-100, .fx-c, .transition;
  }

  img {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.uploaded {
    &:hover .file-input-label-clear {
      opacity: 1;
      visibility: visible;
    }

    .txt {
      display: none;
    }

    img {
      display: block;
    }
  }

  input {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    @extend .pos-a;
  }
}

.btn {
  background: none;
  border: none;
}

.link-col {
  color: $link-col;
}
.main-col{
  color: $main-col;
}
.gray-col{
  color: $gray-col;
}
.text-col{
  color: $text-col;
}

time {
  font-size: 14px;
}

a {
  text-decoration: none;
  @extend .transition;

  &.base-col {
    color: #fff;

    &:hover {
      color: $main-col;
    }
  }

  &.wh-col {
    &:hover {
      color: $main-col;
    }
  }

  &.blue-col {
    svg path {
      fill: #086ab0
    }

    &:hover {
      color: $link-col;

      svg path {
        fill: $link-col;
      }
    }
  }

  &:hover {
    text-decoration: none;
  }
}

ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

* {
  box-sizing: border-box;
  outline: none!important;
}

body {
  margin: 0;
  padding: 0!important;
  color: $base-col;
  font-family: $fontHeading, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

img {
  max-width: 100%;
}
textarea:focus{
  outline:none;
}
//form input button
button:hover, button:focus {
  outline: none;
}

button {
  //font-family: $fontBase, sans-serif;
  @extend .cur-p;
  //form input button
}

//+-+-+-+-+-+-+-+-+-+- | table | +-+-+-+-+-+-+-+-+-+-


//+-+-+-+-+-+-+-+-+-+- | fonts | +-+-+-+-+-+-+-+-+-+-

h1, h2, h3, h4, h5, h6, p {
  font-weight: 400;
  margin: 0;

  .no-margin {
    margin: 0;
  }
}

.h1, h1 {
  font-size: 60px;
}

h2, .h2 {
  font-size: 40px;
}

h3, .h3 {
  font-size: 30px;
}

h4, .h4 {
  font-size: 20px;
}

h5, .h5 {
  font-size: 18px;
}

h6, .h6 {
  font-size: 16px;
}
.f-14{
  font-size: 14px;
}

@media screen and (max-width: 575px) {
  .h1, h1 {
    font-size: 1.6rem;
  }

  h2, .h2 {
    font-size: 1.5rem;
  }

  h3, .h3 {
    font-size: 1.4rem;
  }

  h4, .h4 {
    font-size: 1.3rem;
  }

  h5, .h5 {
    font-size: 1.2rem;
  }

  h6, .h6 {
    font-size: 1rem;
  }
  //fonts
}


@media screen and (max-width: 479px) {
  .h1, h1 {
    font-size: 1.6rem;
  }

  h2, .h2 {
    font-size: 1.5rem;
  }

  h3, .h3 {
    font-size: 1.3rem;
  }

  h4, .h4 {
    font-size: 1.2rem;
  }
  h5, .h5 {
    font-size: 1rem;
  }
  //fonts
}


.txt-editor {
  //overflow-x: auto;
  line-height: (26/16);

  h1, h2, h3, h4, h5, h6, p {
    margin-bottom: 0.5rem;
  }

  ul {
    list-style: disc;
    padding: initial;
    margin-bottom: .5rem;
  }


  table, td {
    border: 2px solid #f7f7f7;
  }

  table {
    width: auto;
    border-collapse: collapse;
    text-align: center;
    td {
      padding-left: 5px;
      padding-right: 5px;
    }
    thead {
      td, th {
        @extend .text-upper;
      }
    }
  }

}

