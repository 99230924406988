@mixin importFont($font, $weight, $path) {
  @font-face {
    font-family: $font;
    font-weight: $weight;
    src: url($path);
  }
}

$fontHeading: 'helveticaneue';
$fontHeadPath: '../fonts/helveticaneue/';

//++++++++++++++++++++++++++++++++++ | regular | ++++++++++++++++++++++++++++++++++
@include importFont($fontHeading, 400, $fontHeadPath + 'HelveticaKVAFont.ttf')

//++++++++++++++++++++++++++++++++++ | Thin | ++++++++++++++++++++++++++++++++++
@include importFont($fontHeading, 300, $fontHeadPath + 'HelveticaNeueLt.ttf')

//++++++++++++++++++++++++++++++++++ | medium | ++++++++++++++++++++++++++++++++++
@include importFont($fontHeading, 500, $fontHeadPath + 'HelveticaNeueMed.ttf')
//++++++++++++++++++++++++++++++++++ | semiBold | ++++++++++++++++++++++++++++++++++
//@include importFont($fontHeading, 600, $fontHeadPath + 'RobotoSlab-Regular.ttf')
//++++++++++++++++++++++++++++++++++ | Bold | ++++++++++++++++++++++++++++++++++
@include importFont($fontHeading, 700, $fontHeadPath + 'HelveticaNeueBd.ttf')

//++++++++++++++++++++++++++++++++++ | Extra-Bold | ++++++++++++++++++++++++++++++++++
@include importFont($fontHeading, 800, $fontHeadPath + 'HelveticaNeue BlackCond.ttf')




//$fontBase: 'proximaNova';
//$fontBasePath: '../fonts/proximaNova/';
//++++++++++++++++++++++++++++++++++ | regular | ++++++++++++++++++++++++++++++++++
//@include importFont($fontBase, 400, $fontBasePath + 'proximaRegular.otf')

//++++++++++++++++++++++++++++++++++ | semiBold | ++++++++++++++++++++++++++++++++++
//@include importFont($fontHeading, 600, $fontHeadPath + 'ProximaNova-SemiboldIt.ttf')
